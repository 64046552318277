export const EVENT_LISTEN_ACTIONS = {
  AUTHENTICATED_USER: "authenticated-user",
  FETCHED_CHATROOMS_BY_USER: "fetched-chatrooms-by-user",
  FILTERED_CHATROOMS_BY_USER: "filtered-chatrooms-by-user",
  FETCHED_MESSAGES_BY_CHATROOM: "fetched-messages-by-chatroom",
  MESSAGE_SENT: "message-sent",
  UPDATE_CHATROOMS_LISTEN: "updated-room",
  ARCHIVE_ROOM_LISTEN: "archived-room",
  UNARCHIVE_ROOM_LISTEN: "actived-room-from-archive",
  CREATED_SINGLE_CHATROOM: "created-single-chatroom",
  NEW_CREATED_SINGLE_CHATROOM: "new-created-single-chatroom",
  // FETCHED_SINGLE_CHATROOM_DETAILS:'fetched-single-chatroom-details',
  DELETED_CHAT_INACTIVE: "leaved-chat-room",
  DELETED_SINGLE_ROOM_CONVERSATION: "deleted-single-room-conversation",
  MUTED_ROOM_NOTIFICATION: "muted-room-notification",
  UNMUTED_ROOM_NOTIFICATION: "unmuted-room-notification",
  SEENED_MESSAGE: "seened-messge",
  SCHEDULED_INTERVIEW: "scheduled-interview",
  CONFIRMED_INTERVIEW: "confirmed-interview",
  CANCELLED_INTERVIEW: "cancelled-interview",
  RESCHEDULED_INTERVIEW: "rescheduled-interview",
  INTERVIEWER_WALKOUTED: "interviewer-walkouted",
  CANDIDATE_WALKOUTED: "candidate-walkouted",
  CANDIDATE_WITHDRAWN: "candidate-withdrawn",
  CLOSED_JOB: "job-closed",
  INTERVIEW_RECORDED: "interview-recorded",
  PERSONAL_RESTART_CHAT: "personal-restart-chat",
  DECISION_TAKEN_BY_RECRUITER: "decision-taken-by-recruiter",
};

export const EVENT_EMIT_ACTIONS: any = {
  //eslint-disable-line
  AUTHENTICATE_USER: "authenticate-user",
  GET_CHATROOMS_BY_USER: "get-chatrooms-by-user",
  SEARCH_CHATROOMS_BY_USER: "search-chatrooms-by-user",
  GET_MESSAGES_BY_CHATROOM: "get-messages-by-chatroom",
  POST_MESSAGE: "post-message",
  POST_ATTACHMENT_MESSAGE: "post-attachment-message",
  // GET_SINGLE_CHATROOM_DETAILS:'get-single-chatroom-details',
  ARCHIVE_ROOM_EMIT: "archive-room",
  UNARCHIVE_ROOM_EMIT: "active-room-from-archive",
  UPDATE_CHATROOMS_EMIT: "update-room",
  CREATE_SINGLE_CHATROOM: "create-single-chatroom",
  DELETE_CHAT_INACTIVE: "leave-chat-room",
  DELETE_SINGLE_ROOM_CONVERSATION: "delete-single-room-conversation",
  MUTE_ROOM_NOTIFICATION: "mute-room-notification",
  UNMUTE_ROOM_NOTIFICATION: "unmute-room-notification",
  SEEN_MESSAGE: "seen-messge",
};
