import { RootState } from "@/Redux/store";
import { WebSocketService } from "@/Services/sockets/WebSocketService";
import { authincateUser } from "@/Utils/constants/EmitFunctions/emitFunctions";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface ConnectionStateProps {
  isOffline: boolean;
}

export default function ConnectionState({ isOffline }: ConnectionStateProps) {
  const [connectionText, setConnectionText] = useState("Connecting...");
  const { user, selectedDashboardData } = useSelector((state: RootState) => state.userProfile);

  const reconnectDelay = 5000; // Delay for reconnection attempts in milliseconds

  const setupWebSocket = () => {
    // Create a new WebSocket connection
    WebSocketService.connect(user?._id, localStorage.getItem("deviceId") as string, selectedDashboardData._id);

    // Update WebSocket state
    WebSocketService.addWSEventListener({
      type: "onstatuschange",
      callBack: (status: string) => {
        setConnectionText(status);
      },
    });
    WebSocketService.addWSEventListener({
      type: "onopen",
      callBack: () => {
        const dataToSend = {
          message: { appUserId: user?._id, userRoleProfileId: selectedDashboardData._id },
        };
        console.log("authincateUser");
        authincateUser(dataToSend);
      },
    });
  };

  useEffect(() => {
    setupWebSocket();

    return () => {
      if (WebSocketService.isConnected()) {
        WebSocketService.close();
      } // Cleanup on component unmount
    };
  }, [user?._id, selectedDashboardData?._id, isOffline]);

  return (
    <></>
    // <div style={{ position: "sticky", zIndex: 3, top: 0 }}>{connectionText}</div>
  );
}
