import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type

/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  messages: [],
  currentPage: 1,
  limit: 10,
  totalResults: 0,
  totalPages: 0,
  newMessage: {},
  roomFiles: {},
  hasMore: false,
  loaderForMessagesList: false,
};

export const chatMessageSlice = createSlice({
  name: "chatMessageSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setMessages: (state, action) => {
      if (action?.payload?.list) {
        const mergedList = [...state.messages, ...action?.payload?.list];
        // const deduplicatedList = mergedList.reduce((acc: any, room: any) => {
        //   if (!acc.some((r: any) => r._id === room._id)) acc.push(room);
        //   return acc;
        // }, []);
        state.messages = mergedList;
        state.hasMore = action?.payload?.hasMore; //eslint-disable-line
        // state.currentPage = action?.payload?.pagination?.page;
        // state.totalResults = action?.payload?.pagination?.totalResults;
        // state.totalPages = action?.payload?.pagination?.totalPages;
      } else {
        const mergedList = action?.payload?.list;
        // const deduplicatedList = mergedList.reduce((acc: any, room: any) => {
        //   if (!acc.some((r: any) => r._id === room._id)) acc.push(room);
        //   return acc;
        // }, []);
        state.messages = mergedList;
        // state.currentPage = action?.payload?.pagination?.page;
        // state.totalResults = action?.payload?.pagination?.totalResults;
        // state.totalPages = action?.payload?.pagination?.totalPages;
      }
    },
    setNewMessages: (state, action) => {
      state.messages = [action?.payload?.message, ...state.messages];
      state.newMessage = { ...action?.payload?.message };
    },
    setUpdatedMessage: (state, action) => {
      console.log(state.messages, action, "Checking");
      state.messages = state.messages?.map((item: any) =>
        item?._id === action?.payload?._id ? { ...item, ...action?.payload } : item
      );
    },
    setClearChatMessages: (state) => {
      (state.messages = []), (state.hasMore = false);

      state.newMessage = {};
      state.messages = [];
    },
    setRoomFiles: (state, action) => {
      state.roomFiles = action.payload;
    },
    setLoaderForMessagesList: (state, action) => {
      state.loaderForMessagesList = action.payload;
    },
  },

  extraReducers: () => {
    //eslint-disable-line
  },
});

export const {
  setMessages,
  setNewMessages,
  setClearChatMessages,
  setRoomFiles,
  setUpdatedMessage,
  setLoaderForMessagesList,
} = chatMessageSlice.actions;

export default chatMessageSlice.reducer;
