// Your LanguageProvider component
import React, { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "@/Utils/i18n";
import { ComponnetType } from "@/Types/CommonComponents/UiComponentsType";
import { useDispatch, useSelector } from "react-redux";
import { handleRefreshToken } from "@/Utils/CommonFuncs";
import { useRouter } from "next/navigation";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "@/Redux/store";
import UserLayout from "../Layout/UserLayout";
import VisitorLayout from "../Layout/VisitorLayout";
import { getUser, switchRole } from "@/Redux/userProfile.slice";
import { getUserData } from "@/Utils/cognito-auth-functions";
import Authorizer from "./Authorizer/Authorizer";
import abilities from "@/Utils/CANAbility/abilities.json";
import { AbilityContext, ability } from "@/Utils/CANAbility/can";
import Cookies from "universal-cookie";
import { RoleProfileType } from "@/Types/Profile/editProfileTypes";
import ConnectionState from "./ConnectionState/ConnectionState";
import ListneningEvents from "./ListneningEvents/ListneningEvents";
import { toast } from "react-toastify";
import NoInternet from "../UiComponents/NoInternet/NoInternet";

const LanguageProvider = ({ children }: ComponnetType) => {
  const router = useRouter();
  const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>();
  const [offline, setOffline] = useState(!navigator.onLine);
  const { selectedDashboardData, user } = useSelector((state: RootState) => state.userProfile);

  // Axios interceptors and other code remain unchanged...

  useEffect(() => {
    handleRefreshToken();
  }, [router]);

  const handleSyncDifferentTab = () => {
    const currentRole = new Cookies();
    if (
      selectedDashboardData?.profileType &&
      currentRole.get("currentDashboardRole") &&
      selectedDashboardData?.profileType !== currentRole.get("currentDashboardRole")
    ) {
      getUserData().then((res) => {
        if (res) {
          const bodyData = {
            roleProfileId: user?.roleProfiles?.find(
              (roleProfile: RoleProfileType) => roleProfile?.profileType === currentRole.get("currentDashboardRole")
            )?._id,
          };

          const value = {
            role: currentRole.get("currentDashboardRole") ?? "Default",
            resource: "select-dashboard",
            bodyData,
            dispatch,
            updatedRole: currentRole.get("currentDashboardRole") ?? "Default",
            router,
            profileCompletion: user?.accountSettings?.profileCompletion === 0,
          };
          dispatch(switchRole(value));
        }
      });
    } else if (selectedDashboardData?.profileType && !currentRole.get("currentDashboardRole")) {
      currentRole.set("currentDashboardRole", selectedDashboardData?.profileType);
    }
  };

  useEffect(() => {
    getUserData().then((res) => {
      if (res) {
        dispatch(
          getUser({
            resource: "get-user",
            profileRole: selectedDashboardData?.profileType ?? "Default",
          })
        )
          .unwrap()
          .then((res) => {
            if (!selectedDashboardData?.profileType) {
              const bodyData = {
                roleProfileId: res?.userData?.roleProfiles?.find(
                  (roleProfile) => roleProfile?.profileType === "Default"
                )?._id,
              };
              const value = {
                role: "Default",
                resource: "select-dashboard",
                bodyData,
                dispatch,
                updatedRole: "Default",
                router,
                profileCompletion: res?.userData?.accountSettings?.profileCompletion === 0,
              };
              dispatch(switchRole(value));
            }
          });
      }
    });

    window.addEventListener("focus", handleSyncDifferentTab);
    return () => {
      window.removeEventListener("focus", handleSyncDifferentTab);
    };
  }, []);

  const updateNetworkStatus = (event: Event) => {
    if (event.type === "offline") {
      setOffline(true);
      toast.error("Please check your internet connection");
    } else if (event.type === "online") {
      setOffline(false);
      toast.success("Online Back");
    }
  };

  useEffect(() => {
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, [navigator.onLine]);

  return (
    <>
      {offline && <NoInternet />}
      <I18nextProvider i18n={i18n}>
        <AbilityContext.Provider value={ability}>
          <Authorizer resource={abilities.UserLayoutRenderer}>
            {user?._id && <ConnectionState isOffline={offline} />}
            {user?._id && <ListneningEvents />}
            <UserLayout>{children}</UserLayout>
          </Authorizer>
          <Authorizer resource={abilities.UserLayoutRenderer} not>
            <VisitorLayout>{children}</VisitorLayout>
          </Authorizer>
        </AbilityContext.Provider>
      </I18nextProvider>
    </>
  );
};

export default LanguageProvider;
