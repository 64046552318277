// import { setLoading } from "@/Redux/loader/loader.slice";
// import { store } from "@/Redux/store";
import { SkillType } from "@/Types/Profile/editProfileTypes";
import { GoogleAddressObjectType, ShouldBeComponentType } from "@/Types/PropsPassTypes/PropsTypes";
import { jobModel, workAuthorization } from "@/staticData";
import { fetchAuthSession } from "aws-amplify/auth";
import { toast } from "react-toastify";
// import Cookie from "universal-cookie";
import packageJson from "../../package.json";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getLocalstorageData: any = (key: any) => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(key);
  }
};
/* eslint-enable @typescript-eslint/no-explicit-any */
export const getSessionstorageData = (key: string) => {
  if (typeof window !== "undefined") {
    return sessionStorage.getItem(key);
  }
};
//For generate blob to base64
/* eslint-disable @typescript-eslint/no-explicit-any */

export function blobToBase64(blob: any) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

// Generate Istostrings for start date and end Date
interface StartEndDateGeneratorType {
  startMonth: string;
  endMonth: string;
  startYear: string;
  endYear: string;
}
export const generateStartAndEndISOstring = ({
  startMonth,
  endMonth,
  startYear,
  endYear,
}: StartEndDateGeneratorType) => {
  const startDate = new Date(Number(startYear), Number(startMonth) - 1, 1);
  const endDate = new Date(Number(endYear), Number(endMonth), 0);

  return {
    startDate,
    endDate,
  };
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr: any = dataurl?.split(",");
  const mime = arr && arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const getMimeFromBase64 = (dataurl: string) => {
  const arr: any = dataurl?.split(",");
  const mime = arr && arr[0].match(/:(.*?);/)[1];
  return mime;
};

// Get the file details from file link here
export async function checkFileLink(
  link: string,
  docName?: string
): Promise<{ fileSize: string; fileType: string; renamedFile: string } | null> {
  try {
    const response = await fetch(link);
    if (!response.ok) {
      throw new Error("File link is not valid");
    }
    const fileSize = response.headers.get("Content-Length") || "";
    const fileType = response.headers.get("Content-Type") || "";
    const fileName = link.substring(link.lastIndexOf("/") + 1);
    const extension = fileName.split(".").pop();
    const renamedFile = `${docName}.${extension}`;
    return { fileSize, fileType, renamedFile };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default checkFileLink;

// Getting Formated result of Iso string start and end date(format is like Oct 2019)
export const receivingStartEndDateFromISOString = (date: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
  };
  return new Date(date).toLocaleDateString("en-US", options);
};
//Getting duration of with start,end date and with isPresent flag(in format of 3 years 2 months)
export const calculateDurationForExperienceCard = (startDate: string, endDate: string, isPresent: boolean): string => {
  const start = new Date(startDate);
  const end = isPresent ? new Date() : new Date(endDate);
  const diffInMilliseconds = Math.abs(end.getTime() - start.getTime());
  const years = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor((diffInMilliseconds % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
  return `${years} years ${months} months`;
};

// Google Map Data Preparation

export const getAddressObject = (address_components: GoogleAddressObjectType[]) => {
  const ShouldBeComponent: ShouldBeComponentType = {
    street_number: ["street_number"],
    postal_code: ["postal_code"],
    street: ["street_address", "route"],
    province: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
    ],
    address: ["sublocality_level_2", "sublocality", "political", "premise"],
    country: ["country"],
  };

  const address: {
    street_number: string;
    postal_code: string;
    street: string;
    province: string;
    city: string;
    country: string;
    address: string;
    [key: string]: string;
  } = {
    street_number: "",
    postal_code: "",
    street: "",
    province: "",
    city: "",
    country: "",
    address: "",
  };

  address_components.forEach((component) => {
    for (const shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === "country") {
          address[shouldBe] = component.long_name;
        } else if (shouldBe === "address") {
          address[shouldBe] = component.long_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });

  // Fix the shape to match our schema
  // address.address = address.street_number + " " + address.street;
  if (address.country === "US") {
    address.state = address.province;
  }
  return address;
};
// File size calculator
export const bytesToSize = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(`${Math.floor(Math.log(bytes) / Math.log(1024))}`);
  return Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + " " + sizes[i];
};

//Function to return round figure value of any decimal like numbers
export const roundToNearestInteger = (value: number): number => Math.round(value);

// ISO String to date
export const convertISOToDate = (isoDateString: string) => {
  if (isoDateString) {
    const date = new Date(isoDateString);
    const formatter = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    return formatter.format(date);
  }
};
// making first letetr capital of every word
export const firstLetterCapitalFunc = (str: string) => {
  if (str) {
    return str
      .split(" ") // Split the string by spaces into an array of words
      .map(
        (
          word // Map over each word in the array
        ) => word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter and keep the rest of the word unchanged
      )
      .join(" "); // Join the array back into a single string with spaces
  }
};

/**
 *
 */
export const handleRefreshToken = async () => {
  const { tokens } = await fetchAuthSession();
  if (tokens?.accessToken?.payload?.exp) {
    if (
      window.location.pathname === "/" ||
      window.location.pathname.includes("/auth/") ||
      window.location.pathname.includes("/internal-login")
    ) {
      window.location.href = "/feeds";
    }
    const timeDifference = tokens?.accessToken?.payload?.exp * 1000 - Date.now();
    setTimeout(async () => {
      await fetchAuthSession();
      if (
        window.location.pathname === "/" ||
        window.location.pathname.includes("/auth/") ||
        window.location.pathname.includes("/internal-login")
      ) {
        // const cookie = new Cookie();
        window.location.href = "/feeds";
      }
    }, timeDifference + 1000);
  } else if (window.location.pathname.includes("/internal-login")) {
    window.location.replace("/");
  }
};

// for getting the needed individual value of min and max experience
export const mapExperienceToMinMax = (experience: string, otherExperience: string) => {
  let minExperience, maxExperience;

  switch (experience) {
    case "No Experience Needed":
      minExperience = 0;
      maxExperience = 0;
      break;
    case "Under 1 Year":
      minExperience = 0;
      maxExperience = 1;
      break;
    case "1- 3 Years":
      minExperience = 1;
      maxExperience = 3;
      break;
    case "3-5 Years":
      minExperience = 3;
      maxExperience = 5;
      break;
    case "5-7 Years":
      minExperience = 5;
      maxExperience = 7;
      break;
    case "7-10 Years":
      minExperience = 7;
      maxExperience = 10;
      break;
    case "10-13 Years":
      minExperience = 10;
      maxExperience = 13;
      break;
    case "Other":
      minExperience = Number(otherExperience);
      maxExperience = Number(otherExperience);
      break;
    default:
      minExperience = null;
      maxExperience = null;
      break;
  }

  return { minExperience, maxExperience };
};
//For getting the required string part if i have min and max experience values
export const minMaxExpereinceToMainExp = (minExperience: number, maxExperience: number): string | null => {
  if (minExperience === 0 && maxExperience === 0) {
    return "No Experience Needed";
  } else if (minExperience === 0 && maxExperience === 1) {
    return "Under 1 Year";
  } else if (minExperience === 1 && maxExperience === 3) {
    return "1- 3 Years";
  } else if (minExperience === 3 && maxExperience === 5) {
    return "3-5 Years";
  } else if (minExperience === 5 && maxExperience === 7) {
    return "5-7 Years";
  } else if (minExperience === 7 && maxExperience === 10) {
    return "7-10 Years";
  } else if (minExperience === 10 && maxExperience === 13) {
    return "10-13 Years";
  } else if (minExperience > 13 || maxExperience > 13) {
    return "Other";
  } else {
    return null;
  }
};

//This section is for getting Work Mode format during edit-job
type WorkType = { title: string; value: string };

const workTypes: WorkType[] = jobModel;

export const workModelFormatting = (values: string[]): WorkType[] => {
  return workTypes.filter((workType) => values.includes(workType.value));
};
//This section is for getting Working authorization format during edit-job

const workAuthorizationTypes: WorkType[] = workAuthorization;

export const workAuthorizationFormatting = (values: string[]): WorkType[] => {
  return workAuthorizationTypes.filter((workAuthorizationType) => values.includes(workAuthorizationType.value));
};

//This section is for getting tech skills in the required format during edit-job
export const techSkillsformat = (arr: string[]): { title: string; value: string }[] => {
  return arr?.map((str) => ({
    title: str.toUpperCase(),
    value: str.toLowerCase(),
  }));
};

export const atobConvertor = (data: string) => {
  return atob(data);
};
export const btoaConvertor = (data: string) => {
  return btoa(data);
};
//Conversion of salary into K like format
export const formatSalary = (salary: number) => {
  if (salary >= 1000 && salary <= 1000000) {
    return `${Math.round(salary / 1000)}k`;
  }
  if (salary >= 1000000) {
    return `${Math.round(salary / 1000000)}m`;
  }
  return `${Math.round(salary)}`;
};
//Conversion of salary type to respective format like yr
export const formatSalaryType = (salaryType: string) => {
  if (salaryType === "Per Year") {
    return "yr";
  }
  if (salaryType === "Per Hour") {
    return "hr";
  }
  if (salaryType === "Per Month") {
    return "mo";
  }
  if (salaryType === "Per Week") {
    return "wk";
  }
};

//Get list of skills comapre with all skills list and user's skills
export const filterLanguages = (languages: SkillType[], givenLanguages: string[]) => {
  return languages.filter((lang) => givenLanguages?.includes(lang.code));
};
//Convert the skills only with value and code(array of objects)
export const conversionOfSkillsTitleValue = (skillsList: SkillType[]) => {
  const skillListOption = skillsList?.map((skill: SkillType) => {
    return {
      title: skill?.name,
      value: skill?.code,
    };
  });

  return skillListOption;
};

//Skills getting updated from dropdown when one of skills selected
export const optionCheckerSkills = (skillsList: SkillType[], techSkills: string[]) => {
  const valuesToRemoveUpdated = techSkills.map((item: any) => item.value); //eslint-disable-line
  const filteredArrayUpdated = conversionOfSkillsTitleValue(skillsList)?.filter(
    (item: any) => !valuesToRemoveUpdated.includes(item.value)
  ); //eslint-disable-line
  return filteredArrayUpdated;
};
//Format skill selction so that user not able to select the space or the skills which is not in dropdown
export const formatSkillSelction = (value: any, field: string, formikField: any) => {
  //eslint-disable-line
  let foundStringIndex = -1;

  // Find the index of the first string in the array
  for (let i = 0; i < value.length; i++) {
    if (typeof value[i] === "string") {
      foundStringIndex = i;
      break;
    }
  }

  // If a string is found, replace it with the desired object
  if (
    foundStringIndex !== -1 &&
    !value?.filter((item: any) => item?.value === value[foundStringIndex].toLowerCase())?.length
  ) {
    //eslint-disable-line
    value[foundStringIndex] = {
      title: value[foundStringIndex].toUpperCase(),
      value: value[foundStringIndex].toLowerCase(),
    };
  }
  return formikField.setFieldValue(
    field,
    value?.filter((item: any) => typeof item !== "string")
  ); //eslint-disable-line
};

// Conversion of iso to mm/dd/yyyy format
export const dateToMMDDYYYYConverter = (isoDate: string) => {
  const date = new Date(isoDate);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const getCurrentDateIntoISOString = () => {
  const currentDate = new Date();
  const isoString = currentDate.toISOString();
  return isoString;
};
//Checking the deadline is expired in edit job or clone job
export const isDeadlineExpired = (dateString: string) => {
  const inputDate = new Date(dateString);
  const today = new Date();

  // Set the time of today's date to 00:00:00 to only compare the date part
  today.setHours(0, 0, 0, 0);

  if (inputDate < today) {
    return null;
  }

  return dateString;
};

export const getFileNameFromURL = (url: string) => {
  // Get the last part of the URL by splitting it at '/'
  const path = url.split("/").pop() as string;

  // Extract the file name by splitting the path at the last '.' and taking the first part
  // let fileName = path.split('.').shift();

  return path;
};

export const getRoundedPercentage = (matchingPercentage: number): number => {
  // Round the matchingPercentage to the nearest integer
  const roundedPercentage = Math.round(matchingPercentage);

  // Return the formatted string with the rounded percentage
  return roundedPercentage;
};

//This is for the message of Work in progress
export const toastMessageOfWIP = () => {
  toast.info("Work In Progress");
};

export const durationInNumber = (duration: string) => {
  if (duration === "30 min") {
    return 30;
  }
  if (duration === "45 min") {
    return 45;
  }
  if (duration === "1 hr") {
    return 60;
  }
  if (duration === "1 hr 30 min") {
    return 90;
  }
  if (duration === "2 hr") {
    return 120;
  }
};

export const durationInString = (duration: number) => {
  if (duration === 30) {
    return "30 minutes";
  }
  if (duration === 45) {
    return "45 minutes";
  }
  if (duration === 60) {
    return "1 hour";
  }
  if (duration === 90) {
    return "1 hour 30 minutes";
  }
  if (duration === 120) {
    return "2 hours";
  }
};
// date format like 21 Mar 2024
export const formatDateToDayMonthYear = (isoString: string): string => {
  const date = new Date(isoString);

  // Options for day, short month, and full year
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  return date.toLocaleDateString("en-GB", options);
};

export const formatDateToTimeandDate = (dateString: string) => {
  const date = new Date(dateString);

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
  };

  const time = date.toLocaleTimeString("en-US", timeOptions);
  const formattedDate = date.toLocaleDateString("en-US", dateOptions);

  return `${time}, ${formattedDate}`;
};

//get app version by this function
export const getAppVersion = (): string => {
  return packageJson.version;
};
//clear local storage with keep some keys

export const clearLocalStorageExceptSomeKey = (keysToKeep: string[]): void => {
  // Create a record to hold the values of the keys you want to keep
  const valuesToKeep: Record<string, string | null> = {};

  // Retrieve the values of the keys you want to keep
  keysToKeep.forEach((key) => {
    const value = localStorage.getItem(key);
    if (value !== null) {
      valuesToKeep[key] = value; // Store the value for the key to keep
    }
  });

  // Clear localStorage
  localStorage.clear();

  // Restore the values for the keys you want to keep
  Object.keys(valuesToKeep).forEach((key) => {
    localStorage.setItem(key, valuesToKeep[key]!); // Use non-null assertion
  });
};

//For getting flag according to time format before 24 hours

// export const isBefore24Hours = (interviewDate: string): boolean => {
//   const interviewDateTime = new Date(interviewDate);
//   const currentDateTime = new Date();
//   const before24Hours = new Date().setHours(interviewDateTime.getHours() - 24);
//   if (currentDateTime > new Date(before24Hours) && currentDateTime < interviewDateTime) {
//     return true;
//   }
//   else {
//     return false;
//   }
//   // const hoursDifference = (interviewDateTime.getTime() - currentDateTime.getTime()) / (1000 * 60 * 60);

//   // // Check if the interview date is in the past or within the next 24 hours
//   // return interviewDateTime < currentDateTime || (hoursDifference <= 24 && hoursDifference >= 0);
// };
export const isBefore24Hours = (interviewDate: string): boolean => {
  const interviewDateTime = new Date(interviewDate);
  const currentDateTime = new Date();

  // Calculate the datetime 24 hours before the interview date
  const before24Hours = new Date(interviewDateTime.getTime() - 24 * 60 * 60 * 1000);

  // Return true if the current time is within 24 hours before or exactly 24 hours before,
  // or if the interview date is in the past.
  return (
    (currentDateTime <= interviewDateTime && currentDateTime >= before24Hours) || currentDateTime > interviewDateTime
  );
};

//Time to show in chat message section

// formatTime.ts
export const formatToTimeStringForChat = (isoString: string): string => {
  const date = new Date(isoString);
  const now = new Date();

  // Calculate the difference in days
  const oneDayMs = 24 * 60 * 60 * 1000; // Milliseconds in a day
  const dateStartOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const nowStartOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const diffInDays = Math.floor((nowStartOfDay.getTime() - dateStartOfDay.getTime()) / oneDayMs);

  // Format the time
  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(date);

  if (diffInDays === 0) {
    // Today
    return `Today, ${formattedTime}`;
  } else if (diffInDays === 1) {
    // Yesterday
    return `Yesterday, ${formattedTime}`;
  } else if (diffInDays < 7) {
    // Within the last week
    const dayOfWeek = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(date);
    return `${dayOfWeek}, ${formattedTime}`;
  } else {
    // More than a week ago
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
    return `${formattedDate}, ${formattedTime}`;
  }
};
export const formatOnlyTime = (dateString: string) => {
  const date = new Date(dateString);
  const options: any = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleTimeString("en-US", options);
};
export const formatToConditionalTimeString = (isoString: string): string => {
  const date = new Date(isoString);
  const now = new Date();

  // Calculate the difference in days
  const oneDayMs = 24 * 60 * 60 * 1000; // Milliseconds in a day
  const dateStartOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const nowStartOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const diffInDays = Math.floor((nowStartOfDay.getTime() - dateStartOfDay.getTime()) / oneDayMs);

  if (diffInDays === 0) {
    // Today: Return only the time
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
  } else if (diffInDays === 1) {
    // Yesterday: Return "Yesterday"
    return "Yesterday";
  } else if (diffInDays < 7) {
    // Within the last week: Return day of the week
    return new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(date); // e.g., "Mon", "Tue"
  } else {
    // More than a week ago: Return formatted date (DD-MM-YYYY)
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  }
};

export function formatFileName(fileName: string) {
  // Get the current timestamp
  const timestamp = Date.now();

  // Find the last dot in the filename to separate the name and extension
  const lastDotIndex = fileName.lastIndexOf(".");

  // If there's no dot or it's the first character (hidden file), return the original name
  if (lastDotIndex <= 0) return fileName;

  // Split the filename into the base name and extension
  const name = fileName.substring(0, lastDotIndex);
  const extension = fileName.substring(lastDotIndex);

  // Return the formatted filename
  return `${name}_${timestamp}${extension}`;
}

export const extractFileName = (fileName: string) => {
  const lastUnderscoreIndex = fileName.lastIndexOf("_");
  const lastDotIndex = fileName.lastIndexOf(".");

  // Check if there is a timestamp and an extension
  if (lastUnderscoreIndex !== -1 && lastDotIndex > lastUnderscoreIndex) {
    return (fileName.substring(0, lastUnderscoreIndex) + fileName.substring(lastDotIndex)).split("/").pop();
  }

  return fileName.split("/").pop();
};
export const extractFullFileName = (fileName: string) => {
  // Split the URL by "/" and take the last part
  const lastSegment = fileName.split("/").pop();

  if (!lastSegment) return fileName;

  // Get the position of the last dot (for the file extension)
  const lastDotIndex = lastSegment.lastIndexOf(".");

  // If there's no dot, return the last segment as it is
  if (lastDotIndex === -1) return lastSegment;

  // Return the full name including the extension
  return lastSegment.substring(0, lastDotIndex) + lastSegment.substring(lastDotIndex);
};

export const getChatDeleteOptions = (
  roomDetails: { roomStatus?: string; roomType?: string; isMute: boolean },
  presentRole: string
): { title: string }[] => {
  return roomDetails?.roomStatus !== "Inactive"
    ? [
        { title: roomDetails?.isMute ? "Turn on notification for this chat" : "Turn off notification for this chat" },
        {
          title: roomDetails?.roomStatus === "Archive" ? "Unarchive" : "Archive",
        },
        {
          title:
            roomDetails?.roomType === "Group"
              ? presentRole === "Interviewer" || presentRole === "Candidate"
                ? "Leave Chat"
                : "Close Chat"
              : "Delete Conversation",
        },
      ]
    : [
        {
          title: "Delete Chat",
        },
      ];
};

export const formatDateWithMarker = (inputText: string): string => {
  const startMarker = "OG-DATE-TEXT-START";

  // Check if the start marker is present
  if (inputText.includes(startMarker)) {
    const startIndex = inputText.indexOf(startMarker) + startMarker.length;

    // Extract the ISO date string
    const isoDateString = inputText.slice(startIndex).trim();

    // Parse the date
    const date = new Date(isoDateString);
    const isValidDate = !isNaN(date.getTime()); // Check if the date is valid
    console.log(date, "dateCheck");
    const formattedDate = isValidDate
      ? new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          timeZone: "UTC", // Ensuring we are working with UTC, can adjust as needed
        }).format(date)
      : "Invalid date"; // Handle invalid dates

    // Replace the marker and date with the result
    console.log(inputText.slice(0, inputText.indexOf(startMarker)) + formattedDate, "timeCheck");
    return inputText.slice(0, inputText.indexOf(startMarker)) + formattedDate;
  }

  // If the marker is not found, return the input text unchanged
  return inputText;
};
